<template>
  <div>
    <van-cell-group title="审核信息">
      <audit ref="audit" :app-params="{ coreKey: $route.query.id,  mkCode: 'TUITION',coreCode:'TUITION_PAY' , status:$route.query.status,level:1}" />
    </van-cell-group>
    <van-form ref="form">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuName" readonly label="姓名" />
        <van-field v-model="form.stuNo" readonly label="学号" />
        <van-field v-model="form.stuNo" readonly label="缴纳状态">
          <template #input>
            {{ form.payStatus?'已缴纳':'未缴纳' }}
          </template>
        </van-field>
        <van-field v-model="form.stuNo" readonly label="年度">
          <template #input>
            {{form.schYear}}
          </template>
        </van-field>
        <van-field v-model="form.amount" label="缴纳金额" />
        <van-field v-model="form.remark" rows="3" class="textarea-style" arrow-direction="" label="缴纳说明" type="textarea" maxlength="300" />
      </van-cell-group>
      <!-- 其他信息 -->
      <van-cell-group title="证明附件" class="upload-file" style="margin-bottom:65px;">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" :preview="true" v-model="form.fileId" miz="STU_TUITION_PAY" biz="storage" :multiple="3" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
        </file-upload>
      </van-cell-group>
    </van-form>
  </div>
</template>

<script>

import { getTermYear } from '@/utils/index'
import { mapGetters } from 'vuex'
import fileUpload from "@/components/FileUpload";
import crudDailyTuitionPay from '@/api/modules/daily/dailyTuitionPay'
import audit from "@/view/modules/common/audit";


export default {
  name: "StuPayReport",
  components: { fileUpload, audit },
  dicts: ['xj_nj', 'xj_xqgl'],
  data() {
    return {
      form: {
        id: null,
        stuNo: null,
        stuName: null,
        payStatus: true,
        remark: null,
        amount: null,
        files: null,
        fileId: [],
        schYear: null,
      },
      statusDict: [
        { label: "已缴纳", value: true },
        { label: "未缴纳", value: false },
      ],
      loading: false,
      refsh: true
    }
  },
  watch: {
    "dict.xj_xqgl": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0 && !this.$route.query.id) {
          this.schYearDefault();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    this.$nextTick((e) => {
      crudDailyTuitionPay.findOneById(this.$route.query.id).then((res) => {
        this.form = { ...res }
        this.form.fileId = JSON.parse(res.files)
        // 强制刷新组件
        this.refsh = false
        this.$nextTick(() => {
          this.refsh = true
        })
      })
    })
  },
  methods: {
    schYearDefault() {
      let termYear = getTermYear(
        this.dict.xj_xqgl,
        (new Date().getMonth() + 1).toString()
      )
      this.form.schYear = termYear
    },
    uploadResponse(fileIds, data) {
      if (fileIds.length) {
        this.form.fileId = fileIds;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep.radio-other-info {
  .van-cell {
    .van-field__label {
      width: 11.2em;
    }
  }
}

.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.textarea-style {
  height: 100%;
}
</style>